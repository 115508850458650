import { FC } from 'react';
import { dataAttributeProps } from '../../utils/ComponentUtils';
import FlagIcon from '../shared/icon/FlagIcon';
import RiskUtils, { RiskRating } from '../../utils/RiskUtils';
import { useTranslation } from 'react-i18next';
import { RiskRatingKeys } from '../../models/Risk';
import Tooltip from '../shared/Tooltip';
import IconBadge from '../shared/icon/IconBadge';

export type RiskActionIndicatorProps = {
  riskRating: RiskRating;
  text?: string | number;
  flagOnly?: boolean;
};

const RiskActionIndicator: FC<RiskActionIndicatorProps> = (props) => {
  const { riskRating, text, flagOnly } = props;
  const { t } = useTranslation(['risk']);

  return (
    <>
      {flagOnly && <FlagIcon className={`h-5 w-5 flex-shrink-0 ${RiskUtils.textColorForRating(riskRating)}`} />}
      {!flagOnly && (
        <Tooltip text={t('tooltips.grouped', { amount: text, rating: t(RiskRatingKeys[riskRating]) })}>
          {(tooltip) => (
            <div {...tooltip}>
              <IconBadge
                {...dataAttributeProps(props)}
                icon={<FlagIcon className={`h-5 w-5 flex-shrink-0 ${RiskUtils.textColorForRating(riskRating)}`} />}
                text={text}
              />
            </div>
          )}
        </Tooltip>
      )}
    </>
  );
};

export default RiskActionIndicator;
